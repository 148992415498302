import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { OrderEntityInterface } from '@proman/resources/order';
import { Employee, Order, OrderType } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getSystemOptions } from '@proman/store/system-options';
import { ToastService } from '@proman/services/toast.service';

@Component({
    selector: 'pm-order-copy',
    template: `
        <pro-dialog-title title="order_copying"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pro-editable-container label="order">
                {{ order.name }}</pro-editable-container>
            <pro-autoc [value]="manager"
                       [config]="{ label: 'manager', entity: 'employee', entityParams: queryParams, autofocus: false }"
                       (onChange)="set('manager', $event)"></pro-autoc>
            <pro-autoc [value]="type"
                       [config]="{ label: 'order_type', entity: 'order_type', required: isTypeRequired }"
                       (onChange)="set('type', $event)"></pro-autoc>
            <pro-checkbox [value]="preserveProductsQuantities"
                         [config]="{ label: 'preserve_products_quantities' }"
                         (onChange)="setPreserveQuantities($event)"
            ></pro-checkbox>
            <pro-checkbox [value]="copyComments"
                         [config]="{ label: 'copy_manager_comments' }"
                         (onChange)="setCopyComments($event)"
            ></pro-checkbox>
            <pro-datepicker [value]="date"
                           [config]="{ label: 'shipment' }"
                           (onChange)="set('date', $event)"></pro-datepicker>
        </div>
        <pro-dialog-actions (callback)="copy()"
                           [isCallbackDisabled]="disableCallback || (isTypeRequired && !type)"
                           variant="copy"></pro-dialog-actions>
    `
})

export class OrderCopyDialogComponent {
    order: Order;
    type: OrderType;
    manager: Employee;
    orderEntity: OrderEntityInterface;
    date: string;
    disableCallback: boolean;

    queryParams: any;
    preserveProductsQuantities: boolean = false;
    copyComments: boolean = true;
    isTypeRequired: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { order: Order },
        private Entity: Entity,
        private queryExpression: QueryExpressionService,
        private store: Store,
        private dialogRef: MatLegacyDialogRef<OrderCopyDialogComponent>,
        private Toast: ToastService,
    ) {
        this.orderEntity = this.Entity.get('order') as OrderEntityInterface;
        this.order = this.data.order;
        this.type = this.order.type;
        this.manager = this.order.manager;
        this.store.select(getSystemOptions)
            .subscribe((value) => {
                if (value) {
                    this.isTypeRequired = value.compulsoryOrderType;
                }
            });

        this.setQueryParams();

    }

    setQueryParams = () => {
        let customer = this.order.customer;

        this.queryParams = { required: true };

        if (customer) {
            this.queryParams['customers.id'] = customer.id;

        }

        Object.assign(
            this.queryParams,
            {
                'specialisations.type': 'manager',
                'join': ['specialisations']
            }
        );

    };

    set = (property: string, value: any) => {
        this[property] = value;
    };

    copy = () => {
        this.disableCallback = true;
        if (!this.manager) this.Toast.pop('info', 'Manager not entered')
        return this.orderEntity
            .copy({
                id: this.order.id,
                date: this.date,
                manager: this.manager?.id,
                preserveQuantities: this.preserveProductsQuantities,
                copyComments: this.copyComments,
                type: this.type?.id,
            })
            .then((response: any) => this.dialogRef.close(response.data))
            .catch(() => { this.disableCallback = false; });
    };

    setPreserveQuantities = (value: any) => {
        this.preserveProductsQuantities = value;
    };

    setCopyComments = (value: any) => {
        this.copyComments = value;
    };

}
