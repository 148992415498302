import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { ModelItemInterface, ModelService } from '@proman/services/model.service';
import {findById, hasSubstring, removeHtmlTags} from '@proman/utils';
import { ACL } from '@proman/services/acl.service';
import {
    Article,
    Order,
    OrderProduct,
    ProductParameter
} from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getDecimalPlaces } from '@proman/store/system-options';

@Component({
    selector: 'pm-product-edit-dialog',
    template: `
        <pro-dialog-title title="edit.edit_product"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pro-label>{{ 'static_image' | translate }}</pro-label>
            <pm-upload-entity-photo [value]="product.product"
                                    [config]="{ resource: 'product', resizeImageRatio: 1 }"></pm-upload-entity-photo>
            @if (canChangeArticle) {
                <pro-autoc [value]="product.product.article"
                           [config]="{ label: 'article', entity: 'article', required: true, displayKey: 'altName', searchFields: ['name', 'altName'] }"
                           (onChange)="changeArticle($event)"></pro-autoc>
            }
            <pro-autoc [value]="product.product"
                       [config]="{ label: 'name', required: true, entity: 'product', entityParams: { join: ['product', 'product.article', 'product.parameters', 'product.parameters.parameter', 'product.parameters.children', 'product.parameters.children.parameter'] } }"
                       [disabled]="true"
                       (onChange)="model.updateAssociation('product', $event)"></pro-autoc>

            @if (ACL.check('product.show_price')) {
                <div fxLayout="row" fxFlex>
                    <pm-price [value]="product.customerPrice"
                              cdkFocusInitial
                              [config]="{ label: 'customer_unit_price', decimalPlaces: decimalPlaces, currencyDisabled: false }"
                              (onChange)="model.update('customerPrice', $event)"
                              [disabled]="!permissions['order.update_price']">
                    </pm-price>
                    <pro-btn fxFlex pmPadding *ngIf="costExpression"
                             icon="bolt"
                             size="1x"
                             [theme]="product.fixedPrice && 'grey' || 'accent'"
                             (onClick)="model.update('fixedPrice', !product.fixedPrice)">
                    </pro-btn>
                </div>
            }

            <div fxLayout="row" fxLayoutAlign="start center">
                <pm-txt [value]="product.discount"
                        [config]="{ label: 'discount', parseNumber: true, validators: { number: true } }"
                        (onChange)="setProductDiscount($event)"></pm-txt>
                {{ '%' }}
            </div>

            @if (product.product.parameters) {
                @for (parameter of product.product.parameters; track $index) {
                    @if (!parameter._isRerendering) {
                        <pro-parameter [parameter]="parameter"
                                       [config]="{}"
                                       (onChange)="updateParameter(parameter, $event)"
                                       [disabled]="parameter.isLocked"
                                       [hidden]="!parameter.isVisible"
                        ></pro-parameter>
                    }
                }
            }

            <pm-txt [value]="product.product.weight"
                    [config]="{ label: 'weight', parseNumber: true, validators: { number: true } }"
                    (onChange)="setProductWeight($event)"></pm-txt>

            <pm-txt [value]="product.quantity"
                    [config]="{ label: 'quantity', parseNumber: true, validators: { number: true } }"
                    (onChange)="setProductQuantity($event)"></pm-txt>

            <pm-txt [value]="product.packagingQuantity"
                    [config]="{ label: 'packaging_quantity', parseNumber: true, validators: { number: true } }"
                    (onChange)="setProductPackagingQuantity($event)"></pm-txt>

            <pm-txt [value]="getCommentsWithoutTag(product.product.comments)"
                    [config]="{ label: 'comments', type: 'textarea' }"
                    (onChange)="setComment($event)"></pm-txt>

            @if (product.product.customerName) {
                <pro-editable-container label="{{ 'customer_product_name' | translate }}">
                    {{ product.product.customerName }}
                </pro-editable-container>
            }
            @if (product.product.customerData) {
                <pro-editable-container label="{{ 'customer_product_data' | translate }}">
                    {{ product.product.customerData }}
                </pro-editable-container>
            }
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ProductEditDialogComponent {
    permissions: { [key: string]: boolean };
    product: OrderProduct;
    order: Order;
    decimalPlaces: number;
    model: ModelItemInterface;
    orderProductEntity: EntityInterface;
    currUser: CurrUser;
    canChangeArticle: boolean = false;
    article: Article;
    isCustomer: boolean = false;
    costExpression: boolean = true;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private store: Store,
        private Entity: Entity,
        private Model: ModelService,
        public ACL: ACL,
    ) {
        this.store.select(getCurrUser).subscribe((value) => {
           this.currUser = value;
           if (this.currUser) {
               this.permissions = this.currUser.permissions;
               this.isCustomer = this.currUser.type === 'customer';
           }
        });

        this.store.select(getDecimalPlaces, 'order_product').subscribe((value) => this.decimalPlaces = value);

        this.orderProductEntity = Entity.get('order_product');
        this.product = this.data.item;
        this.order = this.data.order;

        this.model = this.Model.get(this.product, this.orderProductEntity);
        this.model.addHandler('productionPrice', this.getPrice);
        this.model.addHandler('customerPrice', this.getPrice);

        if (!this.product.parentOrderProduct && !this.isCustomer) {
            this.orderProductEntity.get({
                'id': this.product?.id,
                'partialJoin': {'product': ['id'], 'product.orderProducts': ['id'], 'product.article': ['primeCostExpression']}
            }).then((response: OrderProduct) => {
                this.costExpression = !!response?.product?.article?.primeCostExpression;
                this.canChangeArticle = response?.product?.orderProducts?.length === 1
            });
        }

        this.handleSubproduct();

    }

    getPrice = () => {
        this.orderProductEntity
            .get({ id: this.product.id })
            .then((response: OrderProduct) => {
                this.product.productionPrice = response.productionPrice;
                this.product.customerPrice = response.customerPrice;
            });
    };

    update(field: string, value: any) {
        const request = { id: this.product.id };

        request[field] = value;

        return this.orderProductEntity.update(request);
    };

    updateParameter(productParameter: ProductParameter, value: any) {
        return (this.Entity.get({ name: 'order_product', post: ['updateProductParameter'] }) as any)
            .updateProductParameter({ productParameterId: productParameter.id, orderProductId: this.product.id, value })
            .then(() => {
                productParameter.value = value;
                this.handleParameterUpdate();
            });
    };

    handleParameterUpdate = () => {
        this.Entity.get('product_parameter')
            .search({ 'product.id': this.product.product.id })
            .then((response: ProductParameter[]) => {
                const parameters = this.product.product.parameters;

                response.forEach((p: ProductParameter) => {
                    let param = findById<ProductParameter>(parameters, p.id);

                    if (param && (param.expression || param.filter || param.value !== p.value)) {
                        param = Object.assign(param, p);

                        param._isRerendering = true;
                        setTimeout(() => param._isRerendering = false);
                    }

                });

                this.handleSubproduct();
            });
    };

    handleSubproduct = () => {
        const product = this.product;
        const parent = product.parentOrderProduct;

        if (!parent) return;

        function handleMaterialParamsFilter() {

            product.product.parameters.forEach((p: ProductParameter) => {

                if (p.parameter.type === 'material' && p.filter) {

                    const pCount = p.filter.split('p(').length - 1;

                    for (let i = 0; i < pCount; i++) {
                        if (hasSubstring(p.filter, 'p(')) {
                            let value: string|number = p.filter;

                            value = value.substr(value.indexOf('p(') + 2); // remove 'p(' from start
                            value = value.substr(0, value.indexOf(')')); // remove ')' from end
                            value = parseInt(value);

                            parent.product.parameters.forEach((pp: any) => {
                                if (pp.parameter?.id === value) p.filter = p.filter.replace(`p(${value})`, pp.value);
                            });
                        }
                    }
                }
            });
        }

        handleMaterialParamsFilter();

    };

    setProductWeight(weight: string) {
        const product = this.product.product;
        product.weight = +weight;
        this.Entity.get('product').update({ id: product.id, weight });
    }

    setProductPackagingQuantity(packagingQuantity: string) {
        const product = this.product;
        product.packagingQuantity = +packagingQuantity;
        this.orderProductEntity.update({ id: product.id, packagingQuantity });
    }

    setProductDiscount(discount: string) {
        const product = this.product;
        product.discount = +discount;
        product.fixedPrice = false;
        this.orderProductEntity.update({ id: product.id, discount, 'fixedPrice': false })
            .then(() => this.orderProductEntity.get({ id: product.id, select: ['customerPrice'] })
                .then((p) => product.customerPrice = p.customerPrice));
    }

    setProductQuantity(quantity: string) {
        const product = this.product;
        product.quantity = String(+quantity);
        this.orderProductEntity.update({ id: product.id, quantity });
    }

    changeArticle(article: Article) {
        (this.Entity.get('order_product') as any).updateArticle({ id: this.product.id, article: article.id });
    }

    setComment(comments: string) {
        const product = this.product.product;
        this.Entity.get('product').update({ id: product.id, comments });
    }

    getCommentsWithoutTag  (value: any) {
        if (value !== null) {
            return (removeHtmlTags(value));
        }
    }
}
