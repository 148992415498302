import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface, EntityNameType } from '@proman/services/entity.service';
import { Workplace } from '@proman/interfaces/entity-interfaces';
import { OnDropEmitType } from '@proman/list-manager/list-manager.component';

@Component({
    selector: 'pm-items-reposition-dialog',
    template: `
        <pro-dialog-title title="reposition"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pro-list-manager *ngIf="items && listMangerConfig"
                             [items]="items"
                             [config]="listMangerConfig"
                             (onDrop)="handleDrop($event)"
            ></pro-list-manager>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ItemsRepositionDialogComponent {
    items: any[] = [];
    entity: EntityInterface;
    listMangerConfig: any;
    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: {
            items: any[],
            entity: EntityNameType,
            params?: any,
            listManagerConfig?: any,
            afterResposition?: () => void
        },
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<ItemsRepositionDialogComponent>,
    ) {
        this.items = data.items;
        this.entity = this.Entity.get(this.data.entity);

        this.listMangerConfig = { label: 'items', ...(this.data.listManagerConfig || {}) };
    }

    handleDrop({ item, position }: OnDropEmitType<Workplace>) {
        this.entity
            .reposition({ ...(this.data.params || {}),
                positionAt: position, positionId: item.id, listManagerConfig: {} })
            .then(() => {
                if (this.data.afterResposition) this.data.afterResposition();
            });
    }

}
